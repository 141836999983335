import React, { useState } from 'react';
import { NavLink, Link, useHistory } from 'react-router-dom';
// Fare rates for different shifts
const fareRates = {
  1: 1500,
  2: 1100,
  3: 1100,
  4: 1100,
  5: 1100,
  6: 1100,
  7: 800,
  8: 800,
  9: 800,
  10: 800
};

// Options for fare calculation intervals
const intervals = {
  "15days": { label: "15 Days", multiplier: 0.5 },
  "30days": { label: "1 Month", multiplier: 1 },
  "2months": { label: "2 Months", multiplier: 2 },
  "6months": { label: "6 Months", multiplier: 6 },
  "1year": { label: "1 Year", multiplier: 12 }
};

const LibraryFareCalculator = () => {
  // State to hold selected shift, from date, to date, interval, and total fare
  const [shift, setShift] = useState();
  const [fromDate, setFromDate] = useState("");
  const [interval, setInterval] = useState("30days");
  const [toDate, setToDate] = useState("");
  const [totalFare, setTotalFare] = useState(0);

  // Function to handle the fare calculation
  const calculateFare = () => {
    if (!fromDate) {
      alert("Please select the From date.");
      return;
    }

    // Calculate the "to" date based on the selected interval
    const from = new Date(fromDate);
    let to = new Date(from);

    switch (interval) {
      case "15days":
        to.setDate(from.getDate() + 15);
        break;
      case "30days":
        to.setMonth(from.getMonth() + 1);
        break;
      case "2months":
        to.setMonth(from.getMonth() + 2);
        break;
      case "6months":
        to.setMonth(from.getMonth() + 6);
        break;
      case "1year":
        to.setFullYear(from.getFullYear() + 1);
        break;
      default:
        return;
    }

    // Set the "to" date in state
    setToDate(to.toISOString().split("T")[0]);

    // Calculate the total fare based on the shift and interval
    const fare = fareRates[shift] * intervals[interval].multiplier;
    setTotalFare(fare);
  };


  return (
    <div className="sticky-booking-form-container">
      <div className="price">
        <span className="old-price"><strike>₹{(totalFare * 1.08).toFixed(2)}</strike></span>
        <span className="discount"> 8.00% off  </span>
        <span className="new-price"> ₹ {totalFare}</span>
        <p>Inclusive of all taxes</p>
      </div>

      <div className="form-group">
        <label>Shift:</label>
        <select className="form-select-lg mb-3" aria-label=".form-select-lg example" value={shift} onChange={(e) => setShift(Number(e.target.value))}>
          <option selected>Shift</option>
          <option value={1}>7AM – 10PM (Full Day)</option>
          <option value={2}>7AM – 5PM (Morning & Afternoon)</option>
          <option value={3}>12PM – 10PM (Afternoon & Evening)</option>
          <option value={4}>7AM – 12PM & 5PM – 10PM (Morning & Evening)</option>
          <option value={5}>5PM - 7AM (Morning & Night)</option>
          <option value={6}>9PM - 12 PM (Night & Morning)</option>
          <option value={7}>7AM – 12 PM (Morning)</option>
          <option value={8}>12PM – 5PM (Afternoon)</option>
          <option value={9}>5PM – 10PM (Evening)</option>
          <option value={10}>9PM - 7AM (Night)</option>
        </select>
      </div>

      <div className="form-group">
        <label>Duration:</label>
        <select className="form-select form-select" aria-label="form-select example" value={interval} onChange={(e) => setInterval(e.target.value)} >
          <option >Days</option>
          <option value="15days">15 Days</option>
          <option value="30days">1 Month</option>
          <option value="2months">2 Months</option>
          <option value="6months">6 Months</option>
          <option value="1year">1 Year</option>
        </select>
      </div>

      <div className="form-group">
        <label>From Date:</label>
        <input
          type="date"
          value={fromDate}
          onChange={(e) => setFromDate(e.target.value)}
        />
      </div>

      <div className="form-group">
        {toDate && (
          <div>
            <label>To Date: </label>
            <input type="date" value={toDate} readOnly />
          </div>
        )}
      </div>

      <button className="calculate-fare-btn" onClick={calculateFare}>
        Calculate Membership Fee
      </button>

      {totalFare > 0 && (
        <div className="total-fare">
          <h4>Total Fare: ₹ {totalFare}</h4>
        </div>
      )}

      <button className="continue-btn">
        <Link to="/checkin" style={{ color: '#fff' }}><strong>Continue to Book</strong></Link>
      </button>

      <p className="policy">All staff vaccinated with all dose</p>
      <p className="policy">Cancellation Policy</p>
      <p className="safety">
        Follow safety measures advised at the Library
      </p>
      <div className="c-1ccy8kh">
        <span>By proceeding, you agree to our</span>
        <a href="/" className="c-nn640c" target="_blank" rel="noreferrer" >
          <span className="c-1u4dhw5"> Library Policies.</span>
        </a>
      </div>
    </div>
  );
}

export default LibraryFareCalculator;