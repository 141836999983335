export const PrivacyPolicies = (props) => {
    return (
        <div className="plan" id="amc" style={{ marginTop: '20vh' }}>
            <div className="container">
                <div className="jumbo-box2 cd">
                    <h2> <b>Privacy Policy</b></h2>
                    <p>At Reader's Rejoice, we value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard your information when you visit our library and self-study center or interact with our services.
                    </p>

                    <h3><b><u> 1. Information We Collect</u></b></h3>
                    <p><b>We may collect the following types of information:</b></p>
                    <p><b>Personal Information:</b> When you register for our services, we may ask you to provide personal information, including but not limited to:</p>
                    <p><b>Name</b></p>
                    <p><b>Email address</b></p>
                    <p><b>Phone number</b></p>
                    <p><b>Membership ID </b>(if applicable)</p>
                    <p><b>Payment information </b>(for membership or service fees)</p><br />
                    <p><b>Usage Data:</b> We collect information about how you use our services, including:</p>
                    <p><b>Your interactions with our website and facilities</b></p>
                    <p><b>Book borrowing history</b></p>
                    <p><b>Study room reservations</b></p>
                    <p><b>Feedback and reviews</b></p>
                    <p><b>Cookies and Tracking Technologies:</b> We may use cookies and similar technologies to enhance your experience. You can manage your cookie preferences through your browser settings.</p>

                    <h3><b><u> 2. How We Use Your Information</u></b></h3>
                    <p><b>We may use the information we collect for various purposes, including:</b></p>
                    <p><b>- </b>To provide and maintain our services.</p>
                    <p><b>- </b>To process transactions and send you notifications related to your account.</p>
                    <p><b>- </b>To improve our services and develop new offerings.</p>
                    <p><b>- </b>To communicate with you, including sending updates, newsletters, and promotional materials.</p>
                    <p><b>- </b>To analyze usage patterns and improve user experience.</p>

                    <h3><b><u> 3. Data Sharing and Disclosure</u></b></h3>
                    <p><b>We do not sell or rent your personal information to third parties. We may share your information in the following circumstances:</b></p>
                    <p><b>With Service Providers:</b> We may employ third-party companies and individuals to facilitate our services, such as payment processors and IT support, who will have access to your information only to perform specific tasks on our behalf.</p>
                    <p><b>Legal Compliance: </b>  We may disclose your information if required to do so by law or in response to valid requests by public authorities.</p>

                    <h3><b><u> 4. Data Security</u></b></h3>
                    <p>We take reasonable measures to protect your information from unauthorized access, loss, or misuse. While we strive to use commercially acceptable means to protect your personal data, we cannot guarantee its absolute security.</p>

                    <h3><b><u> 5. Your Rights</u></b></h3>
                    <p><b>You have the right to:</b></p>
                    <p><b>-</b>Access and update your personal information.</p>
                    <p><b>-</b>Request the deletion of your personal data.</p>
                    <p><b>-</b>Opt-out of marketing communications.</p>
                    <p><b>-</b>Withdraw consent where we are relying on consent to process your personal data.</p>
                    <p><b>-</b>To exercise these rights, please contact us using the contact details below.</p>

                    <h3><b><u> 6. Third-Party Links</u></b></h3>
                    <p>Our services may contain links to third-party websites. We are not responsible for the privacy practices of these websites. We encourage you to review the privacy policies of any third-party sites you visit.</p>

                    <h3><b><u> 7. Changes to This Privacy Policy</u></b></h3>
                    <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date. We encourage you to review this policy periodically for any changes.</p>

                    <h3>Thank you for choosing Reader's Rejoice! Your trust is important to us.</h3>
                    
                </div>

            </div>
        </div>

    );
};
